.area-image {
	width: 90px;
	float: left;
	padding-right: 10px;
}

.image-size-sm {
	width: 80px;
	height: 115px;
}

.image-size-lg {
	width: 200px;
	height: 350px;
}

.text-title {
	height: 24px;
	width: calc(100% - 90px);;
}

.text-global {
	height: 24px;
	display: block;
	width: 100%;
}

.shimmer-data-full {
	width: 100%;
	height: 20px;
}

.shimmer-data-lg {
	width: 600px;
	height: 20px;
}

.shimmer-data-md {
	width: 400px;
	height: 20px;
}

.shimmer-data-sm {
	width: 200px;
	height: 20px;
}