.Shimmer-shimmer-1-1-1 {
	background-size: 800px 300px !important;
}

.shimmer-image-cover {
	width: 100%;
	height: 288px;
}

.shimmer-data-full {
	width: 100%;
	height: 20px;
}

.shimmer-data-lg {
	width: 600px;
	height: 20px;
}

.shimmer-data-md {
	width: 400px;
	height: 20px;
}

.shimmer-data-sm {
	width: 200px;
	height: 20px;
}

.row-url {
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.eps-uservideo {
	font-weight: bold;
	color: red;
}