.area-image-content {
	float: left;
	padding-right: 8px;
}

.area-image-content > img {
	width: 80px;
}

.content-rating {
	color: orange;
}

.title-status-upload {
	display: block;
	text-align: center;
	font-weight: bold;
	font-size: 12px;
}

.status-upload-name {
	display: block;
	text-align: center;
}

.area-status-publish {
	padding-top: 10px;
}

.title-status {
	font-size: 12px;
}

.last-episode {
	font-size: 12px;
	padding-top: 10px;
}

.genre-list-content {
	display: inline;
	list-style: none;
	margin: 0;
	padding: 0;
}

.genre-list-content li {
	display: inline;
}

.genre-list-content li:after {
	content: ", ";
}

.genre-list-content li:last-child:after {
	content: "";
}

.table .uplink{
	color: #337ab7;
	font-size: 12px;
	display: none;
}

.table tr:hover .uplink{
	display: inline;
}

.table .uplink > ul {
	list-style: none;
	padding: 0;
}

.table .uplink > ul > li {
	display: inline;
	padding-right: 10px;
}

.extraClass {
	font-size: 12px !important;
	pointer-events: auto !important;
}
.extraClass:hover {
	visibility: visible !important;
	opacity: 1 !important;
}

.area-episode {
	padding: 5px;
	background-color: #686868;
	color: #fff;
}